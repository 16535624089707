import * as React from "react"

const PreviewPage = () => {
	return (

		<div class="rounded-xl md:rounded-2xl lg:rounded-4xl shadow-md bg-gray-200 m-2 min-w-[95%] bg-gradient-to-bl from-gray-100 via-yellow-100 to-gray-100 bg-no-repeat bg-cover">
		</div>

	)
}

export default PreviewPage
